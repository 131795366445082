import $ from 'jquery'
import LazyLoad from 'lazyload'

const captionWrapper = document.querySelector('.captions')
const captionTitle = document.querySelector('.caption__title')
const captionYear = document.querySelector('.caption__year')
const captionClient = document.querySelector('.caption__client')
const captionText = document.querySelector('.caption__text')
const captionCloseBtn = document.querySelector('.captions__close-btn')

// Get initial caption height.
let captionHeight
window.onload = () => {
  captionHeight = captionWrapper.offsetHeight
  captionWrapper.style.height = captionHeight
}

const showCaptions = () => {
  captionWrapper.style.transition = 'all 175ms ease-in-out 250ms'
  captionWrapper.classList.add('captions--is-showing')
}
const hideCaptions = () => {
  captionWrapper.classList.remove('captions--is-showing')
}

const activateCaptions = newHeight => {
  captionWrapper.style.height = newHeight + "px";
  captionWrapper.classList.add('captions--is-active')
}
const deactivateCaptions = () => {
  captionWrapper.style.height = captionHeight + 'px'
  captionWrapper.style.transitionDelay = '0ms'
  captionWrapper.style.transition = 'all 0ms ease-in-out'
  captionWrapper.classList.remove('captions--is-active')
}

const renderCaptions = content => {
  return new Promise(resolve => {
    captionTitle.innerText = content.title
    captionYear.innerText = content.year
    captionClient.innerText = content.client
    captionText.innerHTML = content.text
    const newHeight = captionHeight + captionText.offsetHeight
    resolve(newHeight);
  })
}

captionCloseBtn.addEventListener("click", deactivateCaptions);

document.querySelectorAll('.project__image').forEach(project => {
  const content = {
    title: project.parentNode.dataset.captionTitle,
    year: project.parentNode.dataset.captionYear,
    client: project.parentNode.dataset.captionClient,
    text: project.parentNode.dataset.captionText
  }

  project.addEventListener('click', () => {
    renderCaptions(content)
      .then( newheight => { activateCaptions(newheight) })
  })

  project.addEventListener('mouseenter', () => {
    if (!captionWrapper.classList.contains('captions--is-active')) {
      renderCaptions(content)
        .then(showCaptions)
    }
  })
  project.addEventListener('mouseleave', hideCaptions)

})


const navigation__btn = document.querySelector('.navigation__btn')
navigation__btn.addEventListener('click', e => {
  e.preventDefault()
  navigation__btn.classList.toggle('is-active')
  $('.navigation').slideToggle(250)
})


let images = document.querySelectorAll(".lazyload");
new LazyLoad(images, {
  root: null,
  rootMargin: "200px",
  threshold: 0
});


let videos = [...document.querySelectorAll("video")];

const options = {
  rootMargin: "0px 0px -100px 0px"
};

function onIntersection(videoEntities) {
  videoEntities.forEach(video => {
    if (video.intersectionRatio > 0) {
      video.target.play();
    } else {
      video.target.pause();
      // console.log("out of view");
    }
  });
}

let observer = new IntersectionObserver(onIntersection, options);
videos.forEach(video => observer.observe(video));